<script>
  import { selected_city, selected_chart_index } from "./store.js"

  
  const all_cities = city_names

  const chart_button_text = [
    ["48h", "48 Stunden"],
    ["5d3h", "5 Tage/3h"],
    ["7d", "7 Tage"]
  ]

  render_chart($selected_chart_index)
  
  async function render_chart(chart_index) {
    if (!document.querySelector("#chartContainer_" + chart_index + "_"+chart_button_text[2][0])) {
      setTimeout(() => {render_chart(chart_index)}, 200)
    } else {
      $selected_chart_index = chart_index
      for (let city_index=0; city_index<3; ++city_index) {
	for (let i=0; i<chart_button_text.length; ++i) {
	  const text = chart_button_text[i]
	  const html_id = "#chartContainer_" + city_index + "_" + text[0]
	  const container = document.querySelector(html_id)

	  if (i == $selected_chart_index) {
	    container.style.display = "block"
	    // const window_id = "chart" + text[0]
	    // window[window_id].options.title.text = text[1]
	    // window[window_id].update()
	  } else {
	    container.style.display = "none"
	  }
	  container.style.height = "500px"	  
	}
      }
    }
  }

</script>

{#each all_cities as city, city_index}
  <div id="container_{city}" class:hidden={city_index != $selected_city}>
    {#each chart_button_text as text, chart_index}
      <button on:click={() => {render_chart(chart_index)}}
	class="button" class:active={chart_index == $selected_chart_index}
	>
	{text[1]}
      </button>
    {/each}
    
    <canvas id="chartContainer_{city_index}_48h" style="height: 500px; width: 100%;"></canvas>
    <canvas id="chartContainer_{city_index}_7d" style="height: 500px; width: 100%;"></canvas>
    <canvas id="chartContainer_{city_index}_5d3h" style="height: 500px; width: 100%;"></canvas>
  </div>
{/each}

<style>
  .hidden {
    display: none;
  }

  .active {
    border: #000 solid;
    border-width: 5px;
  }
</style>
