<script>
  import { get_currency } from "./helper.js"
  
  //const currencies = ["EUR", "DKK", "USD"]
  let start_amount = 1
  $: amount = start_amount || 1
  //let end_amount = 1
  //let currency_from = "EUR"
  //let currency_to = "DKK"

  const currency = get_currency()

</script>


<h2>Geld Umrechner</h2>

{#await currency}
  <p>1 EUR = 1 DKK</p>
  <p>1 DKK = 1 EUR</p>
{:then cur}
  <label>
    Amount:
    <input type="number"
	   placeholder="1"
	   onfocus="this.value=''"
	   bind:value={start_amount}/>
  </label>
  <p>
    {amount} EUR = {(cur.base_to*amount).toFixed(4)} DKK
  </p>
  <p>
    {amount} DKK = {(cur.to_base*amount).toFixed(4)} EUR  
  </p>
{/await}
    

<style>
  input {
    width: 150px;
  }
</style>
