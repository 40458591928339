import { writable } from "svelte/store"

const stored_city = localStorage.getItem("selected_city") || 1
export const selected_city = writable(stored_city)
selected_city.subscribe(value => {
  localStorage.setItem("selected_city", value)
})

const stored_chart_index = localStorage.getItem("selected_chart_index") || 2
export const selected_chart_index = writable(stored_chart_index)
selected_chart_index.subscribe(value => {
  localStorage.setItem("selected_chart_index", value)
})
