<script>
  import { get_week, get_all_weather } from "./helper.js"
  import { theme } from "./theme.js"
  import MoneyCalculator from "./money_calculator.svelte"
  import WeatherDescription from "./weather_description.svelte"
  import Charts from "./charts.svelte"

  let promise_weather = get_all_weather()
  promise_weather.then(res => {
    console.log(res)
    init_charts(res[0], 0)
    init_charts(res[1], 1)
    init_charts(res[2], 2)
  })

  function get_wind_ratio(weather_data) {
    const wind_speed1 = Math.max(0.01, weather_data[1].current.wind_speed)
    const wind_speed2 = Math.max(0.01, weather_data[2].current.wind_speed)
    return (wind_speed1/wind_speed2).toFixed(3)
  }

  const is_rubbish = !(get_week() % 2)

</script>

<main>
  <h2 on:click={theme.toggle}>Aktuelles Wetter</h2>

  <WeatherDescription weather_promise={promise_weather}/>

  {#await promise_weather}
    <p>Kruså-Wind ist xxx mal stärker als der Frankfurt-Wind.</p>
  {:then weather}
    <p>Kruså-Wind ist {get_wind_ratio(weather)} mal stärker als der Frankfurt-Wind.</p>
  {:catch error}
    <p>{error.message}</p>
  {/await}
  <p>Müll wird <b>{is_rubbish ? "diese" : "nächste"}</b> Woche abgeholt.</p>

  <br>
  <MoneyCalculator/>
  <br>
  <br>
  <hr>
  <Charts/>
  <br>
  <br>
  <br>
  
</main>


