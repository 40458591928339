// import { theme } from "./store.js"
import { writable } from "svelte/store"

const stored_theme = localStorage.getItem("theme") || "light"
update_theme(stored_theme)

function create_theme() {
  const { subscribe, set, update } = writable(stored_theme)

  return {
    subscribe,
    toggle: () => update(theme => {
      return toggle_theme(theme)
    })
  }
}

function update_theme(theme) {
  localStorage.setItem("theme", theme)  
  
  document.body.classList.toggle("dark-mode", theme === "dark")
  changeColor(theme === "dark" ? colors["mainDark"] : colors["mainLight"])

  return theme
}

function toggle_theme(theme) {
  if (theme == "light") {
    return update_theme("dark")
  }
  if (theme == "dark") {
    return update_theme("light")
  }
}

export const theme = create_theme()
