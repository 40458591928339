<script>
  import { selected_city } from "./store.js"
  
  export let weather_promise

  const transform = "translate(-50%, -50%)"
  
</script>

<div id="center">
  <div class="flex-grid">
    {#await weather_promise}
      <p>Loading...</p>
    {:then weather_data}
      {#each weather_data as city, index}
	<div class="col" class:active={index == $selected_city}
	     on:click={() => {$selected_city = index}}>
          <p>Stadt: <b>{city.main.city}</b></p>
          <p>{city.current.weather[0].description} | {city.current.temp}°C</p>
          <p>{city.current.wind_speed}m/s bzw. {(city.current.wind_speed*3.6).toFixed(2)}km/h</p>
          <p>Windstärke {city.current.wind_force[0]} - {city.current.wind_force[1]}</p>
	  <div class="wind-container">
	    <img src="static/windrose.png" alt="windrose">
	    <p class="wind-arrow"
	       style="transform: {transform} rotate({city.current.wind_deg+180}deg)">⥉</p>
	  </div>
	</div>
      {/each}
    {:catch error}
      <p>{error.message}</p>
    {/await}
  </div>
</div>


<style>
  .active {
    border: #555 solid;
    border-radius: 20px;
  }

  .col {
    cursor: pointer;
  }

  .wind-container {
    position: relative;
  }

  .wind-arrow {
    margin: 0px;
    font-size: 15vw;
    color: hsl(0, 100%, 50%);
    position: absolute;
    top: 50%;
    left: 50%;
  }

  img {
    max-width: 90%;
    width: 90%;
    height: auto;
  }
</style>
